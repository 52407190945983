import React, { useState, ChangeEvent, FormEvent } from 'react';
import '../../styles/LoginForm.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const SignupForm: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire({
        title: 'Error!',
        text: 'Passwords do not match',
        icon: 'error',
        confirmButtonColor: '#4b4b4e',
        confirmButtonText: 'Ok',
      });
      return;
    }

    // Show a SweetAlert2 popup
    await Swal.fire({
      title: 'Success!',
      text: 'User added successfully!',
      icon: 'success',
      confirmButtonColor: '#4b4b4e',
      confirmButtonText: 'Ok',
    });

    // Navigate to the login page after the user dismisses the popup
    navigate('/login');
  };

  return (
    <div className="mx-4 my-2">
      <form method="post" onSubmit={handleSubmit}>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="firstName" className="input-group-text">
            <i className="bi bi-person"></i>
          </label>
          <input
            id="firstName"
            type="text"
            className="form-control input-box"
            maxLength={30}
            placeholder="First Name"
            autoComplete="off"
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFirstName(e.target.value)
            }
            required
          />
        </div>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="lastName" className="input-group-text">
            <i className="bi bi-person"></i>
          </label>
          <input
            id="lastName"
            type="text"
            className="form-control input-box"
            maxLength={30}
            placeholder="Last Name"
            autoComplete="off"
            value={lastName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setLastName(e.target.value)
            }
            required
          />
        </div>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="useremail" className="input-group-text">
            <i className="bi bi-envelope"></i>
          </label>
          <input
            id="useremail"
            type="email"
            className="form-control input-box"
            maxLength={40}
            placeholder="Email Address"
            autoComplete="off"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            required
          />
        </div>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="password" className="input-group-text">
            <i className="bi bi-lock"></i>
          </label>
          <input
            id="password"
            type="password"
            className="form-control input-box"
            maxLength={40}
            placeholder="Password"
            autoComplete="off"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            required
          />
        </div>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="confirmPassword" className="input-group-text">
            <i className="bi bi-lock"></i>
          </label>
          <input
            id="confirmPassword"
            type="password"
            className="form-control input-box"
            maxLength={40}
            placeholder="Confirm Password"
            autoComplete="off"
            value={confirmPassword}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setConfirmPassword(e.target.value)
            }
            required
          />
        </div>
        <div className="my-4 text-center">
          <input
            type="submit"
            className="btn btn-lg btn-outline-dark"
            id="usersubmit"
            value="Sign Up!"
          />
        </div>
        <hr />
      </form>
    </div>
  );
};

export default SignupForm;
