import React, {useEffect, useState} from 'react';
import { Navbar, Nav, FormControl, Form, InputGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from '../styles/Navbar.module.css';
import { FaSearch, FaShoppingBag, FaUser } from 'react-icons/fa';
import { CiSearch } from "react-icons/ci";
import { PiBagThin, PiUserThin } from "react-icons/pi";
import useMobileDetect from "../hooks/useMobileDetect";
/*Mueda dropdown is not working properly, the logo should be static positon the entire time */

const DesktopNavBar = () => (
    <div className="NavPageContainer">
      <Navbar bg="white" expand="lg" className={styles.topNavbar}>
        <Navbar.Toggle aria-controls="top-navbar-nav"/>
        <Navbar.Collapse
            id="top-navbar-nav"
            className="justify-content-between"
        >
          <Nav className={styles.leftNav}>
            <select className={styles.formControl}>
              <option>VANCOUVER</option>
              <option>MONTREAL</option>
              <option>TORONTO</option>
              <option>LOS ANGELES</option>
              <option>NEW YORK</option>
            </select>
          </Nav>
          <Navbar.Brand href="/" className={styles.navlogo}>
            MUEDA
          </Navbar.Brand>
          <Nav className={styles.rightNav}>
            <Nav.Link as={NavLink} to="/login" className={styles.iconLink}>
              <PiUserThin className={styles.icon}/>
            </Nav.Link>
            <Nav.Link
                as={NavLink}
                to="/shopping-bag"
                className={styles.iconLink}
            >
              <PiBagThin className={styles.icon}/>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div style={{height: 2, border: '1px #EDEDED solid'}}></div>
      <Navbar bg="white" expand="lg" className={styles.bottomNavbar}>
        <Navbar.Toggle aria-controls="bottom-navbar-nav"/>
        <Navbar.Collapse
            id="bottom-navbar-nav"
            className="justify-content-between"
        >
          <Nav className={styles.leftBottomNav}>
            <Nav.Link as={NavLink} to="/products" className={styles.navLink}>
              Menswear
            </Nav.Link>
            <Nav.Link as={NavLink} to="/products" className={styles.navLink}>
              Womenswear
            </Nav.Link>
            <Nav.Link as={NavLink} to="/storePage" className={styles.navLink}>
              Brands
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about" className={styles.navLink}>
              About
            </Nav.Link>
          </Nav>
          <Nav  className={styles.rightNav}>
          <Form className="d-flex">
            <InputGroup className={styles.searchInputGroup}>
              <FormControl
                  type="search"
                  placeholder="Search"
                  className={styles.searchInput}
                  aria-label="Search"
              />
              <InputGroup.Text className={styles.searchIcon}>
                <CiSearch/>
              </InputGroup.Text>
            </InputGroup>
          </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
);
const MobileNavBar = () => (
    <Navbar bg="white" expand="lg" className={styles.combinedNavbar}>
      <Navbar.Brand href="/" className={styles.navlogo}>
        MUEDA
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="combined-navbar-nav" />
      <Navbar.Collapse id="combined-navbar-nav" className="justify-content-between">
        <Nav className={styles.leftNav}>
          <select className={styles.formControl}>
            <option>VANCOUVER</option>
            <option>MONTREAL</option>
            <option>TORONTO</option>
            <option>LOS ANGELES</option>
            <option>NEW YORK</option>
          </select>
          <Nav.Link as={NavLink} to="/products" className={styles.navLink}>
            Menswear
          </Nav.Link>
          <Nav.Link as={NavLink} to="/products" className={styles.navLink}>
            Womenswear
          </Nav.Link>
          <Nav.Link as={NavLink} to="/storePage" className={styles.navLink}>
            Brands
          </Nav.Link>
          <Nav.Link as={NavLink} to="/about" className={styles.navLink}>
            About
          </Nav.Link>
        </Nav>
        <Form className="d-flex">
          <InputGroup className={styles.searchInputGroup}>
            <FormControl
                type="search"
                placeholder="Search"
                className={styles.searchInput}
                aria-label="Search"
            />
            <InputGroup.Text className={styles.searchIcon}>
              <CiSearch />
            </InputGroup.Text>
          </InputGroup>
        </Form>
        <Nav className={styles.rightNav}>
          <Nav.Link as={NavLink} to="/login" className={styles.iconLink}>
            <PiUserThin className={styles.icon} />
          </Nav.Link>
          <Nav.Link as={NavLink} to="/shopping-bag" className={styles.iconLink}>
            <PiBagThin className={styles.icon} />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
);

const NavBar = () => {
  const isMobile = useMobileDetect();

  return isMobile ? <MobileNavBar /> : <DesktopNavBar />;
};
export default NavBar;
