// Footer.tsx
import React from 'react';
import '../styles/Footer.css';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa'; // Importing the CSS file for styling

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // To dynamically set the current year

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <h1 className="footer-logo">MUEDA</h1>
        </div>
        <div className="footer-center">
          <nav>
            <a href="/menswear">Menswear</a>
            <a href="/womenswear">Womenswear</a>
            <a href="/about">About</a>
            <a href="/brands">Brands</a>
            <a href="/contact">Contact</a>
            <a href="/privacy">Privacy Policy</a>
          </nav>
        </div>
        <div className="footer-right">
          <a href="https://www.facebook.com" className="social-icon">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" className="social-icon">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com" className="social-icon">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com" className="social-icon">
            <FaLinkedinIn />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {currentYear} MUEDA. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
