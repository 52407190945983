import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './../styles/CheckoutPage.css'; // Import the CheckoutPage stylesheet
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const CheckoutPage: React.FC = () => {
  // Load the Stripe script
  const stripePromise = loadStripe(
    'pk_test_51OisEFJp3TLgm0DXNLyqxxi81S3zvnpklFhiZAbe3HlVQAKQJp3p8BbTF5PvtkLzintB07wcMgbqngUyAzts5bQe00XbBVcCnn'
  );

  const stripeInternal = require('stripe')(
    'sk_test_51OisEFJp3TLgm0DX0THGVKIMRfXq6asKt0qTo2YimYfxyuOhGHynbI8WDayr1ehVVBk4C0PvGgqqKC5VU6az4j2600Zyif2DHi'
  );

  const createPaymentIntent = async () => {
    const paymentIntent = await stripeInternal.paymentIntents.create({
      amount: 1099, // Amount in cents
      currency: 'cad',
      // Optionally, you can add other payment settings like payment_method_types or a customer ID
    });

    return paymentIntent;
  };

  // createPaymentIntent()
  //     .then((paymentIntent) => alert(paymentIntent.client_secret))
  //     .catch((error) => console.error(error));
  const stripe = useStripe();
  const elements = useElements();
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      'pi_3P3DLvJp3TLgm0DX1uewwfXG_secret_WxixuDHp8w3hxC71s4eF36hBn',
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // Pass the paymentMethod.id to your server to create a charge or a Payment Intent
    }
  };

  return (
    <Container fluid="lg" className="checkout-container">
      <Row>
        <Col lg={8}>
          <h2 className="mb-4">CHECKOUT</h2>
          <Form onSubmit={handleSubmit} className="checkout-form">
            <h4 className="mb-3">SHIPPING ADDRESS</h4>

            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={'234'}
                  // onChange={}
                />
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={'234'}
                  // onChange={}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={'234'}
                  // onChange={}
                  className="input-field"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={'234'}
                  // onChange={}
                />
              </Col>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={'234'}
                  // onChange={}
                />
              </Col>
            </Row>

            <h4 className="mb-3">SHIPPING METHOD</h4>

            <Row>
              <label>
                <input type="radio" name="name1" value="value1" />
                Option 1
              </label>
              <label>
                <input type="radio" name="name1" value="value2" />
                Option 1
              </label>
            </Row>

            <h4 className="mb-3">PAYMENT METHOD</h4>
            <Form onSubmit={handleSubmit}>
              <Elements stripe={stripePromise} options={options}>
                <form onSubmit={handleSubmit}>
                  <PaymentElement />
                </form>
              </Elements>
            </Form>
            <Button variant="dark" type="submit" className="place-order-btn">
              PLACE ORDER
            </Button>
          </Form>
        </Col>
        <Col lg={4}>
          <Card className="order-summary">
            <Card.Body>
              <Card.Title>ORDER SUMMARY</Card.Title>
              {/* Order summary details */}
              {/* ... */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPage;
