import React, {useEffect, useState} from 'react';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';
import {CartItem} from "../model/CartItem";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import im from "../images/WhatsApp Image 2024-03-13 at 1.27.35 AM.jpeg";
import Cookies from "js-cookie"
import '../styles/ShoppingCart.css';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import {useNavigate} from "react-router-dom";
import {useCart} from "../hooks/Carthooks";
import FeaturedProducts from "../components/FeaturedProducts";
import ForYouProducts from '../components/ForYouProducts';

const ShoppingCart = () => {
  // Calculating the total amount - this would likely be more complex in a real app
  const removeItem = (removerItem: CartItem) => {
    // does not exist
    const updatedCart = cartItems.filter((item) => item.id != removerItem.id || item.size != removerItem.size || item.color != removerItem.color);
    setCartItems(updatedCart);

    // recalculate total

    setCurrentTotal(Array.isArray(updatedCart)
        ? updatedCart.reduce((acc, item) => acc + item.price * item.quantity, 0)
        : 0);
  }
  const [cartItems, setCartItems] = useCart();

  const initTotal =  Array.isArray(cartItems)
      ? cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
      : 0;


  const [currentTotal, setCurrentTotal] = useState<number>(initTotal);
  const navigate = useNavigate();
  const checkout = () => {
    navigate("/checkout")
  }

  return (
      <>
        <Navbar />
        <div className="shopping-cart-container">
              <Col>
                <h2 className="shopping-bag-title">SHOPPING BAG ITEMS</h2>
                <ListGroup variant="flush">

                  {cartItems.map((item, index) => (

                      <ListGroup.Item key={index} className="cart-item flex-column">
                        <div style={{width: "80%", height: 3, background: '#D9D9D9'}} className="line"/>
                        <div className='img-details flex-start'>
                          <img src={item.imageUrl} alt={item.title} className="cart-img" />
                          <div className="item-details flex-column">
                            <div className='title-price flex-row'>
                              <div className="item-title">{item.title}</div>
                              <p className="item-price">${item.price} CAD</p>
                            </div>

                            <div className="item-size">Size: {item.size}</div>

                            <div className="split-qL flex-row">
                              <div className="item-quantity">Quantity: {item.quantity}</div>
                              <div className="item-links ">
                                <Button variant="link">Save For Later</Button>
                                <span>|</span>
                                <Button variant="link" onClick={() => removeItem(item)}>Remove</Button>
                              </div>
                            </div>

                          </div>
                        </div>


                      </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col xs={12} md={4}>
                <Card>
                  <Card.Body className='card-body flex-column'>
                    <Card.Title>ORDER SUMMARY</Card.Title>
                    <div style={{width: "100%", height: 3, background: '#D9D9D9'}} className="line"/>
                    <div className='d-flex flex-row'>
                      <Card.Text className='tot-ship-card'>Total</Card.Text>
                      <Card.Text className='card-total'>{currentTotal} CAD</Card.Text>
                    </div>
                    <div className='d-flex flex-row'>
                      <Card.Text className='tot-ship-card'>Shipping</Card.Text>
                      <Card.Text className='card-shipping'>Calculated at checkout</Card.Text>
                    </div>
                    <div className= 'order-margin d-flex flex-row' >
                      <Card.Text className='order-total'>Order Total</Card.Text>
                      <Card.Text className='order-total-amt'>{currentTotal} CAD</Card.Text>
                    </div>

                    <Button className="checkout-button" onClick={checkout}>Checkout</Button>
                  </Card.Body>
                </Card>
              </Col>

        </div>
        <ForYouProducts title="YOU MAY ALSO LIKE..." />
        <Footer/>
      </>
  );
};

export default ShoppingCart;