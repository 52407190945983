import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import Navbar from '../components/Navbar';

const stripePromise = loadStripe(
  'pk_test_51OisEFJp3TLgm0DXNLyqxxi81S3zvnpklFhiZAbe3HlVQAKQJp3p8BbTF5PvtkLzintB07wcMgbqngUyAzts5bQe00XbBVcCnn'
);
const PaymentPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <Elements stripe={stripePromise}>
        <CheckoutForm></CheckoutForm>
      </Elements>
    </>
  );
};
export default PaymentPage;
