// App.tsx
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProductsPage from './pages/ProductsPage';
import ProductPage from './pages/ProductPage';
import ShoppingCart from './pages/ShoppingCart';
import PaymentPage from './pages/PaymentPage';
import SellerHome from './pages/Seller/SellerHome';
import Login from './components/login/Login';
import Signup from './components/signunp/Signup';
import StorePage from './pages/StorePage'
const stripePromise = loadStripe(
  'pk_test_51OisEFJp3TLgm0DXNLyqxxi81S3zvnpklFhiZAbe3HlVQAKQJp3p8BbTF5PvtkLzintB07wcMgbqngUyAzts5bQe00XbBVcCnn'
);

const App: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="" element={<Home />}></Route>
          <Route path="/products" element={<ProductsPage />}></Route>
          <Route path="/product/:id" element={<ProductPage />}></Route>
          <Route path="/shopping-bag" element={<ShoppingCart />}></Route>
          <Route path="/checkout" element={<PaymentPage />}></Route>
          <Route path="/seller-home" element={<SellerHome />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/storePage" element={<StorePage />}></Route>
        </Routes>
      </Router>
    </Elements>
  );
};

export default App;
