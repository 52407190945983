import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faFacebook,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const DEMO_EMAIL = 'user@example.com';
  const DEMO_PASSWORD = 'user123';

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (email === DEMO_EMAIL && password === DEMO_PASSWORD) {
      // Show a SweetAlert2 popup for successful login
      await Swal.fire({
        title: 'Success!',
        text: 'Login successful!',
        icon: 'success',
        confirmButtonColor: '#4b4b4e',
        confirmButtonText: 'Ok',
      });

      // Redirect to a different page upon successful login
      navigate('/');
    } else {
      // Show an error message if login is unsuccessful
      Swal.fire({
        title: 'Error!',
        text: 'Incorrect email or password',
        icon: 'error',
        confirmButtonColor: '#4b4b4e',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleSocialLogin = (platform: string) => {
    // Add logic for social login
    Swal.fire({
      title: 'Info',
      text: `Login with ${platform} is not implemented yet.`,
      icon: 'info',
      confirmButtonColor: '#4b4b4e',
      confirmButtonText: 'Ok',
    });
  };

  return (
    <div className="mx-4 my-2">
      <form method="post" onSubmit={handleLogin}>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="useremail" className="input-group-text">
            <i className="bi bi-envelope"></i>
          </label>
          <input
            id="useremail"
            type="email"
            className="form-control input-box"
            maxLength={40}
            placeholder="Email Address"
            autoComplete="off"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            required
          />
        </div>
        <div className="input-group col-lg mt-3 my-md-none">
          <label htmlFor="password" className="input-group-text">
            <i className="bi bi-lock"></i>
          </label>
          <input
            id="password"
            type="password"
            className="form-control input-box"
            maxLength={40}
            placeholder="Password"
            autoComplete="off"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            required
          />
        </div>
        <div className="fw-light mt-3">
          Don't have an account?&nbsp;
          <Link to="/signup" className="link">
            Sign Up
          </Link>
        </div>
        <div className="my-4 text-center">
          <input
            type="submit"
            className="btn btn-lg btn-outline-dark"
            id="usersubmit"
            value="Login!"
          />
        </div>
        <hr />
        <div className="or-login-text">Or you can login with</div>
        <div className="social-login-buttons">
          <div
            className="social-login-button google"
            onClick={() => handleSocialLogin('Google')}
          >
            <FontAwesomeIcon icon={faGoogle} />
          </div>
          <div
            className="social-login-button facebook"
            onClick={() => handleSocialLogin('Facebook')}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </div>
          <div
            className="social-login-button twitter"
            onClick={() => handleSocialLogin('Twitter')}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </div>
          <div
            className="social-login-button linkedin"
            onClick={() => handleSocialLogin('LinkedIn')}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
