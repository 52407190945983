import React from 'react';
import ProductCard from './ProcuctionCard';
import '../styles/ProductList.css';

type Product = {
  id: number;
  title: string;
  price: number;
  imageUrl: string;
};

type ProductListProps = {
  products: Product[];
};

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <div className="product-list">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          id={product.id}
          title={product.title}
          price={product.price}
          imageUrl={product.imageUrl}
        />
      ))}
    </div>
  );
};

export default ProductList;
