import React, { useState } from 'react';
import '../styles/FilterSideBar.css';

type Filters = {
  category: string[];
  color: string[];
  size: string[];
  price: string[];
};

interface FilterSidebarProps {
  filters: Filters;
  onFilterChange: (filterType: keyof Filters, value: string) => void;
  onResetFilters: () => void;
}

const FilterSidebar: React.FC<FilterSidebarProps> = ({
  filters,
  onFilterChange,
  onResetFilters,
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(true);
  const [isSizeOpen, setIsSizeOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);

  return (
    <div className="sidebar">
      <div className="card">
        <h2 onClick={() => setIsCategoryOpen(!isCategoryOpen)}>
          {isCategoryOpen ? '–' : '+'} Category
        </h2>
        {isCategoryOpen && (
          <div className="filter-section">
            {['Men', 'Women', 'Kids'].map((category) => (
              <div key={category}>
                <input
                  type="checkbox"
                  id={category.toLowerCase()}
                  name="category"
                  value={category}
                  checked={filters.category.includes(category)}
                  onChange={(e) => onFilterChange('category', e.target.value)}
                />
                <label htmlFor={category.toLowerCase()} className="label">
                  {category}
                </label>
              </div>
            ))}
          </div>
        )}
        <h2 onClick={() => setIsColorOpen(!isColorOpen)}>
          {isColorOpen ? '–' : '+'} Color
        </h2>
        {isColorOpen && (
          <div className="filter-section">
            {['Black', 'Brown', 'Red', 'White'].map((color) => (
              <div key={color}>
                <input
                  type="checkbox"
                  id={color.toLowerCase()}
                  name="color"
                  value={color}
                  checked={filters.color.includes(color)}
                  onChange={(e) => onFilterChange('color', e.target.value)}
                />
                <label htmlFor={color.toLowerCase()} className="label">
                  {color}
                </label>
              </div>
            ))}
          </div>
        )}
        <h2 onClick={() => setIsSizeOpen(!isSizeOpen)}>
          {isSizeOpen ? '–' : '+'} Size
        </h2>
        {isSizeOpen && (
          <div className="filter-section">
            {['XS', 'S', 'M', 'L', 'XL'].map((size) => (
              <div key={size}>
                <input
                  type="checkbox"
                  id={size.toLowerCase()}
                  name="size"
                  value={size}
                  checked={filters.size.includes(size)}
                  onChange={(e) => onFilterChange('size', e.target.value)}
                />
                <label htmlFor={size.toLowerCase()} className="label">
                  {size}
                </label>
              </div>
            ))}
          </div>
        )}
        <h2 onClick={() => setIsPriceOpen(!isPriceOpen)}>
          {isPriceOpen ? '–' : '+'} Price
        </h2>
        {isPriceOpen && (
          <div className="filter-section">
            {['$0-$50', '$50-$100', '$100-$200', '$200+'].map((price) => (
              <div key={price}>
                <input
                  type="checkbox"
                  id={price}
                  name="price"
                  value={price}
                  checked={filters.price.includes(price)}
                  onChange={(e) => onFilterChange('price', e.target.value)}
                />
                <label htmlFor={price} className="label">
                  {price}
                </label>
              </div>
            ))}
          </div>
        )}
        <button className="reset-button" onClick={onResetFilters}>
          Reset Filter
        </button>
      </div>
    </div>
  );
};

export default FilterSidebar;
