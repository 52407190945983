import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import '../styles/HeroCarousel.css'; // Make sure to create this CSS file for styling
import heroImage from '../images/hero_section_resized.jpg';
import hero2 from '../images/fashion_hero_section_new.jpg'
import hero3 from '../images/fashion_hero_section_less_height.jpg'

const heroData = [
  {
    id: 1,
    image: require('../images/img-hero1.png'),
    title: 'The perfect design for your website',
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab suscipit dicta nulla. Consequuntur obcaecati officiis, labore doloribus non tempore impedit consequatur ab dolor. Explicabo quam repellendus vero omnis, nisi odio!',
    link: 'https://www.google.com'
  },
  {
    id: 3,
    image: require('../images/img-hero3.jpeg'),
    title: 'Enjoy the Difference',
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab suscipit dicta nulla. Consequuntur obcaecati officiis, labore doloribus non tempore impedit consequatur ab dolor. Explicabo quam repellendus vero omnis, nisi odio!',
    link: 'https://www.twitter.com'
  }

]
const HeroCarousel: React.FC = () => {
  return (
      <section id="home" className="hero-block">
        <Carousel>
          {
            heroData.map(hero => {
              return (
                  <Carousel.Item key={hero.id}>
                    <img
                        className="d-block w-100"
                        src={hero.image}
                        alt={"slide " + hero.id}
                    />
                    <Carousel.Caption>
                      <h2>{hero.title}</h2>
                      <p>{hero.description}</p>
                      <a className="btn btn-primary" href={hero.link}>Learn More <i className="fas fa-chevron-right"></i></a>
                    </Carousel.Caption>
                  </Carousel.Item>
              );
            })
          }
        </Carousel>
      </section>
  );
};

export default HeroCarousel;