import React from 'react';
import '../../styles/Login.css';
import LoginForm from './LoginForm';

const Login: React.FC = () => {
  return (
    <div>
      <div className="login-box container text-bg-light rounded-5">
        <h3 className="text-center pt-2 mt-2 fw-semibold">Login</h3>
        <hr />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
