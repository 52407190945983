import React from 'react';
import '../../styles/Signup.css';
import SignupForm from './SignupForm';

const Signup: React.FC = () => {
  return (
    <div>
      <div className="signup-box container text-bg-light rounded-5">
        <h3 className="text-center pt-2 mt-2 fw-semibold">Sign Up</h3>
        <hr />
        <SignupForm />
      </div>
    </div>
  );
};

export default Signup;
