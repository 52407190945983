import React from 'react';
import '../styles/Pagination.css';

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const maxPageButtons = 5;
  let startPage: number, endPage: number;

  if (pageCount <= maxPageButtons) {
    startPage = 1;
    endPage = pageCount;
  } else {
    if (currentPage <= 6) {
      startPage = 1;
      endPage = maxPageButtons;
    } else if (currentPage + 4 >= pageCount) {
      startPage = pageCount - (maxPageButtons - 1);
      endPage = pageCount;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  const pages = Array.from(
    { length: endPage + 1 - startPage },
    (_, i) => startPage + i
  );

  return (
    <div className="pagination">
      <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        First
      </button>
      <button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={currentPage === page ? 'active' : ''}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => onPageChange(Math.min(pageCount, currentPage + 1))}
        disabled={currentPage === pageCount}
      >
        Next
      </button>
      <button
        onClick={() => onPageChange(pageCount)}
        disabled={currentPage === pageCount}
      >
        Last
      </button>
    </div>
  );
};

export default Pagination;
